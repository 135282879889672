import * as idb from 'idb-keyval';
import i18n from './i18n';

const settings = {
  ok: false,

  _: {
    theme: '#ddaacc',
    dark: false,
    locale: i18n.locale,

    userId: null,

    footerSafeArea: false,
    cardIconSimple: true,
    cardIconAfterTrainingAuto: 0,

    recommendingFullPerfectCoef: 0.5,
    rankingAutoLoad: false,
  },

  get(key) {
    return this._[key];
  },

  set(key, value, save = true) {
    this._[key] = value;
    window.vue.$emit(key, value);
    if (save) {
      this.save();
    }
  },

  save() {
    idb.set('settings', this._);
  },
};

async function main() {
  let localSettings = Object.assign({},
    settings._,
    await idb.get('settings') || {},
    window.vue.$route.query || {});

  Object.keys(localSettings).forEach(key => {
    if (settings._[key] !== undefined) {
      settings.set(key, localSettings[key], false);
    }
  });

  settings.save();
  settings.ok = true;
}

main();

export default settings;
