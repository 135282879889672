<template lang="pug">
  .pa-8.text-center(v-if="databaseStatus.status!='ok'")
    v-progress-circular(indeterminate, color="primary")
    .pa-2.caption
      span {{$t('loading.database')}}

  div(v-else)
    v-overlay(:value="!ok || !$user.ok", absolute)
      .pa-8.text-center(v-if="!ok || !$user.ok")
        v-progress-circular(indeterminate, color="primary")
        .pa-2.caption
          span {{$t('loading.user')}}

    Divider
    v-list.py-0(dense)
      template(v-for="user, i in users")
        Divider(v-if="i", :inset="$vuetify.breakpoint.mdAndDown ? 72 : 0")
        User(:user="user", active, :dense="$vuetify.breakpoint.mdAndDown",
          @click.native="$settings.set('userId', user.userProfile.userId)")

      template(v-if="!users.length")
        v-list-item
          v-list-item-subtitle {{$t('users.noUsers')}}

    Divider
    .py-2

    h3 {{$t('users.new')}}

    Divider
    v-list.py-0(dense)
      v-list-item
        v-list-item-content
          v-text-field(v-model="userId", dense, hide-details, :placeholder="$t('users.userId')", @keyup.enter="$settings.set('userId', userId)")

      v-list-item
        v-list-item-content
          v-btn(block, color="secondary", @click="$settings.set('userId', userId)") {{$t('users.link')}}

    Divider
    p.hint {{$t('users.hint')}}

    .py-2

</template>

<script>
import * as idb from 'idb-keyval';
import User from '@/components/User';


export default {
  name: 'Users',

  components: { User },

  data() {
    return {
      users: [],
      ok: false,

      user: this.$user,

      userId: null,
      userIdError: false,

      databaseStatus: this.$sekai.databaseStatus,
    };
  },

  methods: {
    load() {
      this.ok = false;
      // this.users = [];
      idb.values().then(users => {
        users.filter(user => user.user && user.userProfile).forEach(user => {
          let u = this.users.find(u => u.userProfile.userId == user.userProfile.userId);
          if (u) {
            Object.assign(u, user);
          } else {
            this.users.push(user);
          }
        });
        this.ok = true;
      });
    }
  },

  mounted() {
    this.load();
  },

  watch: {
    '$user.ok'() {
      if (this.$user.ok) {
        this.load();
      }
    }
  }
};
</script>
