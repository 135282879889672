<template lang="pug">
  v-list-item(dense, style="overflow: hidden")
    v-list-item-content(style="overflow: visible")
      .mb-n1.mx-n1
        v-slider(dense, hide-details, :value="time / duration * 100", :disabled="!loaded", @change="setTime")

      .d-flex(style="width: 100%")
        v-list-item-action-text {{formatTime(time)}}
        .mx-auto
        v-list-item-action-text {{formatTime(duration)}}

    v-btn.my-2.ml-2(color="primary", outlined, icon, @click.native="click()")
      v-icon(v-if="!loaded && !playing") mdi-play
      v-progress-circular(v-else-if="!loaded && playing", size=24, indeterminate, color="primary")
      v-icon(v-if="loaded && !playing") mdi-play
      v-icon(v-if="loaded && playing") mdi-pause
    //- v-btn.my-2.ml-2(color="primary", outlined, icon, @click.native="playing ? pause() : play()", :disabled="!loaded")
    //-   v-icon
    //-     template(v-if="!playing") mdi-play
    //-     template(v-else) mdi-pause

    //- v-btn.my-2.ml-2(color="primary", outlined, icon, @click.native="loaded ? download() : load()", v-if="!loaded")
    //-   v-icon mdi-refresh

    v-btn.my-2.ml-2(color="primary", outlined, icon, @click.native="download()", v-if="downloadable")
      v-icon mdi-download

    audio(id="player", ref="player", v-on:ended="ended", v-on:canplay="canPlay", :src="src", :preload="playing ? 'auto' : 'none'")

</template>

<script>
export default {
  name: 'Audio',

  props: {
    src: {
      type: String,
      default: null,
    },
    ended: {
      type: Function,
      default: () => { },
    },
    canPlay: {
      type: Function,
      default: () => { },
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    startAt: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      audio: null,
      time: 0,
      duration: 0,
      // firstPlay: true,
      loaded: false,
      playing: false,
    };
  },

  methods: {
    formatTime(time) {
      return `${Math.floor(time / 60)}:${('00' + Math.floor(time % 60)).slice(-2)}`;
    },

    setTime(progress) {
      this.audio.currentTime = this.audio.duration * progress / 100;
    },

    click() {
      if (!this.loaded) {
        this.playing = !this.playing;
        this.load();
      } else if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },

    load() {
      this.audio.load();
    },

    play() {
      this.audio.play();
    },

    pause() {
      this.audio.pause();
    },

    download() {
      this.audio.pause();
      window.open(this.src, 'download');
    },

    onloadstart() {
      this.loaded = false;
      this.time = 0;
      this.duration = 0;
    },

    onloadeddata() {
      this.duration = this.audio.duration;
      this.audio.currentTime = this.startAt;
      this.loaded = true;

      if (this.playing) {
        this.audio.play();
      }

    },

    ontimeupdate() {
      this.time = this.audio.currentTime;
    },

    onplay() {
      this.playing = true;
    },

    onpause() {
      this.playing = false;
    },

    onended() {
      this.playing = false;
    },
  },

  mounted() {
    this.audio = this.$refs.player;
    this.audio.volume = 0.5;
    this.audio.addEventListener('loadstart', this.onloadstart);
    this.audio.addEventListener('loadeddata', this.onloadeddata);
    this.audio.addEventListener('timeupdate', this.ontimeupdate);
    this.audio.addEventListener('play', this.onplay);
    this.audio.addEventListener('pause', this.onpause);
    this.audio.addEventListener('ended', this.onended);
  },

  beforeDestroy() {
    this.audio.removeEventListener('loadstart', this.onloadstart);
    this.audio.removeEventListener('loadeddata', this.onloadeddata);
    this.audio.removeEventListener('timeupdate', this.ontimeupdate);
    this.audio.removeEventListener('play', this.onplay);
    this.audio.removeEventListener('pause', this.onpause);
    this.audio.removeEventListener('ended', this.onended);
  }
};
</script>
