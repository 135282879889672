<template lang="pug">
  v-lazy(:width="size", :height="size")
    div
      v-lazy(:width="size", :height="size")
        div(v-show="afterTraining !== null ? afterTraining : cardIconAfterTrainingSwitch")
          div(:style="assetStyle(true)")
            div(:style="style(true)")

      v-lazy(:width="size", :height="size", :style="{marginTop: `-${size}px`}")
        div(v-show="afterTraining !== null ? !afterTraining : !cardIconAfterTrainingSwitch")
          div(:style="assetStyle(false)")
            div(:style="style(false)")

</template>

<script>
export default {
  name: 'CardIcon',

  props: {
    id: { type: Number, default: -1 },
    size: { default: 40 },
    afterTraining: { type: Boolean, default: null },
  },

  data() {
    return {
      cardIconAfterTrainingSwitch: false,
    };
  },

  computed: {
    simple() {
      return this.$settings.get('cardIconSimple');
    },
    card() {
      return this.$db.cards[this.id];
    },
    limited() {
      return this.withMasterRanks([2]) || this.card.cardRarityType == 'rarity_birthday';
    },
    withCostume() {
      return this.withMasterRanks([0, 1, 3, 5]);
    },
  },

  methods: {
    assetStyle(afterTraining) {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderRadius: this.simple ? '50%' : `${this.size / 156 * 10}px`,
        backgroundImage: `url(${this.$sekai.assetHost}/thumbnail/chara/${this.card.assetbundleName}_${afterTraining ? 'after_training' : 'normal'}.webp)`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
      };
    },
    style(afterTraining) {
      if (!this.card) {
        return {};
      }

      let attribute = {
        backgroundImage: `url(${require(`@/sprites/icon_attribute_${this.card.attr}.png`)})`,
        backgroundSize: this.simple ?
          'calc(100% / 156 * 44) calc(100% / 156 * 44)' :
          'calc(100% / 156 * 35.2) calc(100% / 156 * 35.2)',
        backgroundPosition: '0% 0%',
      };

      let limited = this.simple ? null : this.limited && {
        backgroundImage: `url(${require('@/sprites/badge_limited.png')})`,
        backgroundSize: 'calc(100% / 156 * 112) calc(100% / 156 * 65)',
        backgroundPosition: '100% 0%',
      };

      let rarity = new Array({
        'rarity_1': 1,
        'rarity_2': 2,
        'rarity_3': 3,
        'rarity_4': 4,
        'rarity_birthday': 1,
      }[this.card.cardRarityType]).fill().map((_, i) => ({
        backgroundImage: `url(${require(`@/sprites/rarity_${this.card.cardRarityType == 'rarity_birthday' ? 'birthday' : afterTraining ? 'star_afterTraining' : 'star_normal'}.png`)})`,
        backgroundSize: this.simple ?
          'calc(100% / 156 * 36) calc(100% / 156 * 35)' :
          'calc(100% / 156 * 21.6) calc(100% / 156 * 21)',
        backgroundPosition: this.simple ?
          `calc(100% * (0 + ${i} * 18) / (156 - 36)) calc(100% * (156 - 35 - 0) / (156 - 35))` :
          `calc(100% * (10.8 + ${i} * 21.6) / (156 - 21.6)) calc(100% * (156 - 21 - 10.5) / (156 - 21))`,
      }));

      let frame = this.simple ? null : {
        backgroundImage: `url(${require(`@/sprites/cardFrame_S_${{
          'rarity_1': 1,
          'rarity_2': 2,
          'rarity_3': 3,
          'rarity_4': 4,
          'rarity_birthday': 'bd',
        }[this.card.cardRarityType]}.png`)})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
      };

      let layers = [attribute, limited, ...rarity, frame].filter(x => x);

      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        backgroundImage: layers.map(layer => layer.backgroundImage).join(','),
        backgroundSize: layers.map(layer => layer.backgroundSize).join(','),
        backgroundPosition: layers.map(layer => layer.backgroundPosition).join(','),
      };
    },
    withMasterRanks(masterRanks) {
      let ranks = this.card.masterLessonAchieveResources.map(x => x.masterRank);
      for (let masterRank of masterRanks) {
        if (ranks.indexOf(masterRank) == -1) {
          return 0;
        }
      }
      return 1;
    },
    onAfterTrainingSwitch(cardIconAfterTrainingSwitch) {
      if (this.afterTraining === null) {
        if (this.card.cardRarityType == 'rarity_3' || this.card.cardRarityType == 'rarity_4') {
          this.cardIconAfterTrainingSwitch = cardIconAfterTrainingSwitch;
        }
      }
    }
  },

  mounted() {
    this.$root.$on('cardIconAfterTrainingSwitch', this.onAfterTrainingSwitch);
    this.onAfterTrainingSwitch(this.$settings.get('cardIconAfterTrainingAuto') > 5);
  },

  destroyed() {
    this.$root.$off('cardIconAfterTrainingSwitch', this.onAfterTrainingSwitch);
  }
};
</script>
