import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 540,
      sm: 720,
      md: 1024,
      lg: 1280,
    },
    scrollBarWidth: 0,
  },
  theme: {
    themes: {
      light: { primary: '#666666', secondary: '#999999', bar: '#cccccc' },
      dark: { primary: '#999999', secondary: '#666666', bar: '#333333' },
    },
    options: {
      customProperties: true,
    },
    dark: false,
  },
});
