<template lang="pug">
  v-list-item(dense, @click, v-model="$user.ok && user.userProfile.userId == $user.id && active")
    template(v-if="dense")
      .my-auto
        CardIcon.my-2.mr-4(:id="userCard.cardId", :afterTraining="userCard.defaultImage == 'special_training'")
      //- v-list-item-avatar
      //-   v-img(:src="`${$sekai.assetHost}/thumbnail/chara/${$db.cards[userCard.cardId].assetbundleName}_${userCard.defaultImage == 'special_training' ? 'after_training' : 'normal'}.webp`")
      v-list-item-content
        v-list-item-title
          span {{user.user.userGamedata.name}}
          v-list-item-action-text.mx-2(v-if="user.friendlyName") {{user.friendlyName}}
        v-list-item-subtitle(style="max-width: 192px")
          ProfileHonors(:userProfileHonors="user.userProfileHonors || []", size=20)

    .d-block(v-else, style="width: 100%")
      .d-flex
        .my-auto
          CardIcon.my-2.mr-4(:id="userCard.cardId", :afterTraining="userCard.defaultImage == 'special_training'")
        //- v-list-item-avatar
        //-   v-img(:src="`${$sekai.assetHost}/thumbnail/chara/${$db.cards[userCard.cardId].assetbundleName}_${userCard.defaultImage == 'special_training' ? 'after_training' : 'normal'}.webp`")
        v-list-item-content
          v-list-item-title
            span {{user.user.userGamedata.name}}
            v-list-item-action-text.mx-2(v-if="user.friendlyName") {{user.friendlyName}}
          v-list-item-subtitle.text-wrap {{user.userProfile.word}}
      v-list-item-subtitle.mb-2.mt-n1
        ProfileHonors(:userProfileHonors="user.userProfileHonors || []", size=24)

</template>

<script>
import ProfileHonors from '@/components/ProfileHonors';
import CardIcon from '@/components/CardIcon';

export default {
  name: 'User',

  props: {
    user: {},
    dense: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },

  components: { ProfileHonors, CardIcon },

  computed: {
    userCard() {
      return this.user.userCards.find(card => card.cardId == this.user.userDecks[0].leader);
    }
  },
};
</script>
