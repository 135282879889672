<template lang="pug">
  div
    Divider
    v-list.py-0(dense)
      v-list-item(to="/")
        v-list-item-icon.mr-6
          v-icon mdi-home
        v-list-item-title {{$t('home.title')}}

    Divider
    .py-2

    h3 {{$t('menu.musics')}}

    Divider
    v-list.py-0(dense)
      v-list-item(to="/music")
        v-list-item-icon.mr-6
          v-icon mdi-music
        v-list-item-title {{$t('musics.title')}}
      Divider(inset=64)

      v-list-item(to="/music-summary")
        v-list-item-icon.mr-6
          v-icon mdi-sigma
        v-list-item-title {{$t('musicSummary.title')}}
      Divider(inset=64)

      v-list-item(to="/music-map")
        v-list-item-icon.mr-6
          v-icon mdi-map
        v-list-item-title {{$t('musicMap.title')}}

    Divider
    .py-2

    h3 {{$t('menu.events')}}
    
    Divider
    v-list.py-0(dense)
      v-list-item(to="/event")
        v-list-item-icon.mr-6
          v-icon mdi-calendar
        v-list-item-title {{$t('events.title')}}
      Divider(inset=64)

      v-list-item(to="/rank-match")
        v-list-item-icon.mr-6
          v-icon mdi-podium
        v-list-item-title {{$t('rankMatches.title')}}

    Divider
    .py-2

    h3 {{$t('menu.characters')}}
    
    Divider
    v-list.py-0(dense)
      v-list-item(to="/character")
        v-list-item-icon.mr-6
          v-icon mdi-face-woman
        v-list-item-title {{$t('characters.title')}}
      Divider(inset=64)

      v-list-item(to="/card")
        v-list-item-icon.mr-6
          v-icon mdi-cards
        v-list-item-title {{$t('cards.title')}}

    Divider
    .py-2

    h3 {{$t('menu.profile')}}
    
    Divider
    v-list.py-0(dense)
      v-list-item(to="/profile")
        v-list-item-icon.mr-6
          v-icon mdi-card-account-details-outline
        v-list-item-title {{$t('profile.title')}}
      Divider(inset=64)

      v-list-item(to="/honor")
        v-list-item-icon.mr-6
          v-icon mdi-tag
        v-list-item-title {{$t('honors.title')}}

    Divider
    .py-2

    h3 {{$t('menu.others')}}
    
    Divider
    v-list.py-0(dense)
      v-list-item(to="/settings")
        v-list-item-icon.mr-6
          v-icon mdi-cog
        v-list-item-title {{$t('settings.title')}}
      Divider(inset=64)

      v-list-item(to="/about")
        v-list-item-icon.mr-6
        v-list-item-title {{$t('about.title')}}

    Divider
    .py-2

</template>

<script>


export default {
  name: 'Navigation',
};
</script>