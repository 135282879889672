<template lang="pug">
  div
    Divider
    v-list.py-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('WIP.title')}}
          v-list-item-subtitle.text-wrap {{$t('WIP.guide')}}

      slot

    Divider    
    .py-2

</template>

<script>
export default {
  name: 'WIP',
};
</script>
