<template lang="pug">
  v-app(:style="{'font-family': $t('app.fonts').map(x => `\"${x}\"`).join(', ')}")
    v-navigation-drawer(app, touchless, mobile-breakpoint=1280, v-model="navigation")
      v-app-bar(color="bar", fixed)
        v-toolbar-title {{$t('menu.title')}}

      v-app-bar(color="bar", style="visibility: hidden")
        v-toolbar-title {{$t('menu.title')}}

      .py-2

      Navigation

    v-navigation-drawer(app, touchless, mobile-breakpoint=1024, right)
      v-app-bar(color="bar", fixed)
        v-toolbar-title {{$t('users.title')}}

      v-app-bar(color="bar", style="visibility: hidden")
        v-toolbar-title {{$t('users.title')}}

      .py-2

      Users

    v-app-bar(app, color="bar", style="margin: 0 auto")
      v-btn(icon, @click="navigation=true", v-if="$vuetify.breakpoint.lgAndDown")
        v-icon mdi-menu

      v-btn(icon, @click="$router.back()", v-if="$vuetify.breakpoint.xl")
        v-icon mdi-chevron-left

      v-toolbar-title {{$t('app.title')}}

      v-spacer

      v-btn(icon, @click="$settings.set('dark', !$settings.get('dark'))")
        v-icon mdi-theme-light-dark

      v-btn(icon)
        v-icon mdi-translate

    v-main(style="width: 100%; margin: 0 auto")
      .pa-8.text-center(v-if="!settings.ok")
        v-progress-circular(indeterminate, color="primary")
        .pa-2.caption
          span {{$t('loading.settings')}}

      .pa-8.text-center(v-if="databaseStatus.status!='ok'")
        v-progress-circular(indeterminate, color="primary")
        .pa-2.caption
          span {{$t('loading.database')}}

      div(v-else)
        template(v-if="$vuetify.breakpoint.smAndDown")
          div(v-if="!hasParent || $route.params.id", key="view-a")
            keep-alive: router-view(:style="{ paddingBottom: `${paddingBottom}px` }")

          div(v-if="hasParent && !$route.params.id", key="view-c")
            keep-alive: router-view(:style="{ paddingBottom: `${paddingBottom}px` }", name="parent")

        template(v-else)
          div(v-if="!hasParent", key="view-d")
            keep-alive: router-view(:style="{ paddingBottom: `${paddingBottom}px` }")

          div(v-if="hasParent")
            .d-flex
              .split(style="width: calc((100% - 1px) * 1/3 + 120px)", key="view-e")
                keep-alive: router-view(:style="{ paddingBottom: `${paddingBottom}px` }", name="parent")
              
              v-divider(vertical)

              .split(style="width: calc((100% - 1px) * 2/3 - 120px)", key="view-f")
                keep-alive: router-view(:style="{ paddingBottom: `${paddingBottom}px` }")

    v-footer.d-flex(fixed, padless, elevation=4, v-if="$vuetify.breakpoint.mdAndDown", :style="{ paddingBottom: `${footerPaddingBottom}px`}")
      div(style="width: 56px")
        v-list-item(@click="$router.back()")
          v-list-item-icon.mx-0.my-4
            v-icon mdi-chevron-left

      v-divider(vertical)

      div(style="width: calc(100% - 56px - 1px)")
        template(v-if="$user.ok")
          User(v-if="$profile.user", :user="$profile", @click.native="dialog = true", dense)
          v-list-item(v-else, dense, @click="dialog = true")
            v-list-item-content
              v-list-item-title {{$t('users.notLinked')}}
              v-list-item-subtitle.text-wrap {{$t('users.guide')}}

        v-list-item(v-else, dense)
          v-list-item-content
            v-list-item-subtitle {{$t('loading.user')}}

    v-dialog(v-model="dialog", fullscreen, hide-overlay, transition="dialog-bottom-transition")
      v-card
        v-app-bar(color="bar", fixed)
          v-btn(icon, @click="dialog = false")
            v-icon mdi-chevron-left

          v-toolbar-title {{$t('users.title')}}

        v-app-bar(color="bar", style="visibility: hidden")
          v-btn(icon, @click="dialog = false")
            v-icon mdi-chevron-left

          v-toolbar-title {{$t('users.title')}}

        .py-2

        Users

    v-btn(fab, style="position: fixed; bottom: 8px; left: 8px", @click="$router.back()", v-if="$vuetify.breakpoint.lgAndUp")
      v-icon mdi-chevron-left

</template>

<script>
import * as idb from 'idb-keyval';

import Navigation from '@/components/Navigation';
import Users from '@/components/Users';
import User from '@/components/User';
import settings from '@/settings';

export default {
  name: 'App',

  components: { Navigation, Users, User },

  data() {
    return {
      ok: false,
      search: false,
      input: '',

      refresh: false,

      navigation: true,
      dialog: false,

      databaseStatus: this.$sekai.databaseStatus,
      settings: settings,

      cardIconAfterTrainingAutoId: 0,
      cardIconAfterTrainingSwitch: false,
    };
  },

  computed: {
    hasParent() {
      return !!this.$route.matched[0]?.components?.parent;
    },
    paddingBottom() {
      return this.$vuetify.breakpoint.mdAndDown ? 56 + this.footerPaddingBottom : 0;
    },
    footerPaddingBottom() {
      return this.$settings.get('footerSafeArea') ? 24 : 0;
    }
  },

  methods: {
    onSearch() {
      this.search = false;
      this.$router.push(`/user/${this.input}`);
    },
  },

  mounted() {
    this.$sekai.databaseStatus.promise = this.$sekai.loadDatabase();

    this.$root.$on('refresh', () => {
      this.refresh = true;
      setTimeout(() => location.reload(true), 100);
    });

    this.$root.$on('reloadDatabase', () => {
      idb.del('pjsekai').then(() => {
        location.reload();
      });
    });

    this.$root.$on('dark', dark => {
      this.$vuetify.theme.dark = dark;

      document.querySelector('meta[name=theme-color]').setAttribute('content', this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].bar);
    });

    this.$root.$on('theme', hex => {
      let hex0 = hex.mixColor([undefined, 100, 90], 0.5);
      let hex1 = hex.mixColor([undefined, 90, 75], 0.5);
      let hex2 = hex.mixColor([undefined, 70, 60], 0.5);

      this.$vuetify.theme.themes.light.primary = hex2;
      this.$vuetify.theme.themes.light.secondary = hex1;
      this.$vuetify.theme.themes.light.bar = hex0;
      this.$vuetify.theme.themes.dark.primary = hex1;
      this.$vuetify.theme.themes.dark.secondary = hex2;
      this.$vuetify.theme.themes.dark.bar = '#333333';

      document.querySelector('meta[name=theme-color]').setAttribute('content', this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].bar);
    });

    this.$root.$on('userId', userId => {
      this.$user.load(userId);
      this.dialog = false;
    });

    this.$router.beforeEach((to, from, next) => {
      if (this.dialog) {
        this.dialog = false;
        next(false);
        return;
      }
      if (this.refresh) {
        setTimeout(() => location.reload(true), 100);
        next();
        return;
      }
      next();
    });

    this.$root.$on('cardIconAfterTrainingAuto', cardIconAfterTrainingAuto => {
      if (this.cardIconAfterTrainingAutoId) {
        clearInterval(this.cardIconAfterTrainingAutoId);
        this.cardIconAfterTrainingAutoId = 0;
      }
      if (cardIconAfterTrainingAuto == 0) {
        this.cardIconAfterTrainingSwitch = false;
        this.$root.$emit('cardIconAfterTrainingSwitch', this.cardIconAfterTrainingSwitch);
      }
      else if (cardIconAfterTrainingAuto > 5) {
        this.cardIconAfterTrainingSwitch = true;
        this.$root.$emit('cardIconAfterTrainingSwitch', this.cardIconAfterTrainingSwitch);
      } else {
        this.cardIconAfterTrainingAutoId = setInterval(() => {
          this.cardIconAfterTrainingSwitch = !this.cardIconAfterTrainingSwitch;
          this.$root.$emit('cardIconAfterTrainingSwitch', this.cardIconAfterTrainingSwitch);
        }, cardIconAfterTrainingAuto * 1000);
      }
    });

    // this.$root.$on('afterTraining')

    // let cardIconAfterTrainingSwitch = false;
    // setInterval(() => {
    //   cardIconAfterTrainingSwitch = !cardIconAfterTrainingSwitch;
    //   this.$root.$emit('cardIconAfterTrainingSwitch', cardIconAfterTrainingSwitch);
    // }, 4 * 1000);
  },
};
</script>

<style lang="scss">
.v-list-item__action {
  min-width: 0 !important;
  max-width: 80%;
  margin: auto 0 auto 16px !important;
}
.v-tab {
  font-size: 0.8125rem !important;
}
.v-tabs-items {
  background-color: #00000000 !important;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
.v-slide-group__prev,
.v-slide-group__next {
  display: none !important;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.split {
  height: calc(100vh - 64px);
  overflow-y: scroll;
}
.split::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}
.theme--light.v-application,
.theme--light.v-card,
.theme--light.v-navigation-drawer {
  background: #eeeeee !important;
}
.theme--dark.v-application,
.theme--dark.v-card,
.theme--dark.v-navigation-drawer {
  background: #101010 !important;
}
.theme--light.v-list,
.theme--light.v-tabs-bar {
  background-color: #f6f6f6 !important;
}
.theme--dark.v-list,
.theme--dark.v-tabs-bar {
  background-color: #1e1e1e !important;
}
</style>

<style lang="scss" src="@/scss/text.scss">
