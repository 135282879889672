import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/music/:id?',
    name: 'music',
    components: {
      default: () => import('@/views/Music'),
      parent: () => import('@/views/Musics'),
    },
  },
  {
    path: '/music-summary',
    name: 'music-summary',
    component: () => import('@/views/MusicSummary'),
  },
  {
    path: '/event/:id?',
    name: 'event',
    components: {
      default: () => import('@/views/Event'),
      parent: () => import('@/views/Events'),
    },
  },
  {
    path: '/character/:id?',
    name: 'character',
    components: {
      default: () => import('@/views/Character'),
      parent: () => import('@/views/Characters'),
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings'),
  },
  // {
  //   path: '/user/:id',
  //   name: 'user',
  //   component: () => import('@/views/User'),
  //   props: true,
  // }
];

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (to.path == from.path) return false;
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo, { duration: 0 });
  },
  routes
});

export default router;
