import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import './registerServiceWorker';

Vue.config.productionTip = false;

import sekai from './sekai';
import user from './user';
import settings from './settings';

Object.defineProperty(Vue.prototype, '$sekai', {
  get: function () {
    return sekai;
  }
});

Object.defineProperty(Vue.prototype, '$db', {
  get: function () {
    return sekai.database;
  }
});

Object.defineProperty(Vue.prototype, '$user', {
  get: function () {
    return user;
  }
});

Object.defineProperty(Vue.prototype, '$profile', {
  get: function () {
    return user.profile;
  }
});

Object.defineProperty(Vue.prototype, '$settings', {
  get: function () {
    return settings;
  }
});

Vue.prototype.$eventID = function () {
  return 31;
};


import Audio from '@/components/Audio';
import Divider from '@/components/Divider';
import WIP from '@/components/WIP';
Vue.component(Audio.name, Audio);
Vue.component(Divider.name, Divider);
Vue.component(WIP.name, WIP);

import i18n from './i18n';
const vue = new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');

Date.prototype.format = function (format) {
  format = format.replace(/YYYY/g, this.getFullYear());
  format = format.replace(/MM/g, ('0' + (this.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + this.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + this.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + this.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + this.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + this.getMilliseconds()).slice(-3));

  format = format.replace(/YY/g, ('0' + this.getFullYear()).slice(-2));
  format = format.replace(/M/g, (this.getMonth() + 1));
  format = format.replace(/D/g, (this.getDate()));
  format = format.replace(/H/g, (this.getHours()));
  format = format.replace(/m/g, (this.getMinutes()));
  format = format.replace(/s/g, (this.getSeconds()));
  format = format.replace(/S/g, (this.getMilliseconds()));
  return format;
};

Date.prototype.toLocaleDateString = function () {
  return this.format('YYYY/M/D');
};

Date.prototype.toLocaleString = function () {
  return this.format('YYYY/M/D H:mm:ss');
};

import hsluv from 'hsluv';
String.prototype.mixColor = function (hsl, r = 0.5) {
  let rgb0 = [0, 1, 2].map(i => parseInt(this.slice(-2 * (3 - i), -2 * (3 - i - 1) || undefined), 16) / 255);
  let hsl0 = hsluv.rgbToHsluv(rgb0);
  let hsl1 = hsl.map((x, i) => x == undefined ? hsl0[i] : hsl0[i] * (1 - r) + x * r);
  let rgb1 = hsluv.hsluvToRgb(hsl1);
  let hex1 = '#' + rgb1.map(x => ('00' + Math.round(x * 255).toString(16)).slice(-2)).join('');
  return hex1;
};

window.vue = vue;
