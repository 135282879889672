import sekai from './sekai';
import settings from './settings';
import * as idb from 'idb-keyval';

const $db = sekai.database;

const ratingByRank = function (id, difficulty, rank) {
  let rating = 0, level = 0;
  let musicDifficulty = $db.musicDifficulties[id][difficulty];
  if (rank == 'C') {
    level = musicDifficulty.playLevel + (musicDifficulty.playLevelAdjust || 0);
    rating = level * 5.0;
  } else if (rank == 'F') {
    level = musicDifficulty.playLevel + (musicDifficulty.fullComboAdjust || 0);
    rating = level * 7.5;
  } else if (rank == 'P') {
    level = musicDifficulty.playLevel + (musicDifficulty.fullPerfectAdjust || 0);
    rating = level * 8.0;
  }
  return rating;
};

const user = {
  ok: true,
  id: 0,
  profile: {},

  load(id) {
    this.ok = false;
    this.id = id;

    idb.get(id).then(profile => {
      if (profile && profile.user) {
        this.profile = profile;
      }
    });

    sekai.api(`/api/user/${id}/profile`).then(async response => {
      await sekai.databaseStatus.promise;

      if (this.id != id) {
        return;
      }

      let profile = response;

      if (!profile.user) {
        this.ok = true;
        this.id = 0;
        this.profile = {};
        return;
      }

      profile.$ = {};
      profile.$.userMusics = {};

      for (let userMusic of profile.userMusics) {
        profile.$.userMusics[userMusic.musicId] = Object.assign({}, userMusic, {
          userMusicDifficultyStatuses: {},
        });
        for (let userMusicDifficultyStatus of userMusic.userMusicDifficultyStatuses) {
          profile.$.userMusics[userMusic.musicId].userMusicDifficultyStatuses[userMusicDifficultyStatus.musicDifficulty] = Object.assign({}, userMusicDifficultyStatus, {
            rank: '',
          });
        }
      }

      for (let music of Object.values($db.musics)) {
        if (profile.$.userMusics[music.id]) {
          continue;
        }

        profile.$.userMusics[music.id] = {
          musicId: music.id,
          userMusicDifficultyStatuses: {},
        };
        for (let difficulty of ['easy', 'normal', 'hard', 'expert', 'master']) {
          profile.$.userMusics[music.id].userMusicDifficultyStatuses[difficulty] = {
            musicId: music.id,
            musicDifficulty: difficulty,
            musicDifficultyStatus: 'forbidden',
            userMusicResults: [],
            rank: '',
          };
        }
      }

      for (let userMusicResult of profile.userMusicResults) {
        let rank = {
          'full_perfect': 'P',
          'full_combo': 'F',
          'clear': 'C',
        }[userMusicResult.playResult];
        let userMusicDifficultyStatus = profile.$.userMusics[userMusicResult.musicId].userMusicDifficultyStatuses[userMusicResult.musicDifficulty];
        if (userMusicDifficultyStatus.rank < rank) {
          userMusicDifficultyStatus.rank = rank;
          userMusicDifficultyStatus.rating = ratingByRank(userMusicResult.musicId, userMusicResult.musicDifficulty, rank);
        }
      }

      profile.$.userRating = {
        rating: 0,
        recommendings: [],
        userMusicDifficultyStatuses: Object.values(profile.$.userMusics)
          .map(userMusic => Object.values(userMusic.userMusicDifficultyStatuses))
          .reduce((x, y) => [...x, ...y], [])
          .filter(a => a.rating)
          .sort((a, b) => b.rating - a.rating)
          .slice(0, 39),
      };
      profile.$.userRating.rating = profile.$.userRating.userMusicDifficultyStatuses
        .map(userMusicDifficultyStatus => Math.round(userMusicDifficultyStatus.rating))
        .reduce((x, y) => x + y, 0);

      let meanRating = profile.$.userRating.rating / profile.$.userRating.userMusicDifficultyStatuses.length || 150;
      for (let music of Object.values($db.musics)) {
        for (let difficulty of ['easy', 'normal', 'hard', 'expert', 'master']) {
          let userMusicDifficultyStatus = profile.$.userMusics[music.id].userMusicDifficultyStatuses[difficulty];
          for (let rank of ['P', 'F'])
            if (rank > (userMusicDifficultyStatus.rank || '')) {
              let rating = ratingByRank(music.id, difficulty, rank);
              let ratingDelta = rating - (meanRating + 4);
              let recommendingIndex = (ratingDelta * 0.1) ** 2;

              recommendingIndex += Math.random();
              recommendingIndex += rank == 'P' ? (0.5 - settings.get('recommendingFullPerfectCoef')) * 2 : 0;

              let playLevelAdjust = rank == 'P' ?
                $db.musicDifficulties[music.id][difficulty].fullPerfectAdjust || 0.0 :
                $db.musicDifficulties[music.id][difficulty].fullComboAdjust || 0.0;
              if (playLevelAdjust > 0.5) {
                recommendingIndex += playLevelAdjust - 0.5;
              }

              profile.$.userRating.recommendings.push({
                musicId: music.id,
                musicDifficulty: difficulty,
                rank: rank,
                rating: rating,
                recommendingIndex: recommendingIndex,
              });

              if (ratingDelta < 0) break;
            }
          // let ratingP = ratingByRank(music.id, difficulty, 'P');
          // let ratingF = ratingByRank(music.id, difficulty, 'F');
        }
      }
      profile.$.userRating.recommendings = profile.$.userRating.recommendings
        .sort((a, b) => a.recommendingIndex - b.recommendingIndex)
        .slice(0, 39);

      this.profile = profile;
      idb.set(id, this.profile);
      this.ok = true;
    });
  }
};

export default user;
